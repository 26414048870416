import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\node_modules\\next-video\\dist\\components\\background-video.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\node_modules\\next\\dist\\client\\image-component.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\node_modules\\next\\dist\\client\\link.js");
;
import(/* webpackMode: "eager" */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\app\\globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["AboutSkeleton"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\AboutSkeleton\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["AboutUs"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\AboutUs\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ApplicantCard"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\ApplicantRequest\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["BlogForm"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\BlogForm\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["Blogs"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\Blogs\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["CareerForm"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\CareerForm\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ContactForm"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\Form\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\Globe\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HeroTitle"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\HeroTitle\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HoverCard"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\HoverCard\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["LoginForm"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\loginForm\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["NavbarDemo"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\Navbar\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\OfficePic\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\Portfolio\\showPortfolio.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\ProjectCards\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReviewForm"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\ReviewForm\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["ReviewSection"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\ReviewSection\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\Services\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["SkeletonLoader"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\SkeletonLoader\\index.js");
;
import(/* webpackMode: "eager", webpackExports: ["HoverBorderGradient"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\components\\ui\\hover-border-gradient.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["Providers"] */ "C:\\Users\\DELL\\Desktop\\Ubaid Work\\nubitsoft\\src\\GlobalRedux\\Provider.js");
